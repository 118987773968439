import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import "bootstrap/dist/css/bootstrap.min.css";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./i18n";
import { ThemeProvider, createTheme } from "@mui/material";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b1013ea60067769b8725b6c54f30e98e@o4507769088114688.ingest.de.sentry.io/4507769106792528",
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      mask: [".mask-me"],
      unmask: [".unmask-me"],
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#000000",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline enableColorScheme />
      <App />
    </ThemeProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
