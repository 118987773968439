import React, { Suspense, useEffect, useState } from "react";
import Avantage from "./Avantage";
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import "../styles/EMenuPage.scss";
import { Col, Row } from "react-bootstrap";
import {
  DEFAULT_TEMPLATE,
  EMAIL_COMPANY,
  consoleLog,
  countries,
  goodFormatEmail,
  greenOlive,
  imagePath,
} from "../utils/utils";
import { Footer } from "./Footer";
import "../styles/Footer.scss";
import { NavLink, useNavigate } from "react-router-dom";
import FadeComponent from "./FadeComponent";
import { hasAuthentication } from "../services/Auth";
import { useTranslation } from "react-i18next";
import CountryInfo from "./CountryInfo";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { getActiveRestaurants, sendMail } from "../API/api";
import { TemporaryAlert } from "./PagesUpdate";
import myGa from "../Google-Analytics/myGa";
import { useSelector } from "react-redux";
import Newsletter from "./Newsletter";
import FluidButton from "./buttons/FluidButton";

const avantages = [
  {
    title: "avantage_visuel_label",
    description: `avantage_visuel_description`,
    icon: `${imagePath}/eye.png`,
    image: "",
  },
  {
    title: "avantage_update_label",
    description: `avantage_update_description`,
    icon: `${imagePath}/update.png`,
    image: "",
  },
  {
    title: "avantage_code_label",
    description: `avantage_code_description`,
    icon: `${imagePath}/qr-code.png`,
    image: "",
  },
  {
    title: "avantage_online_label",
    description: `avantage_online_description`,
    icon: `${imagePath}/clock.png`,
    image: "",
  },
];

const EMenuPage = () => {
  const [connected, setConnected] = useState(false);
  const { t } = useTranslation();
  const [country, setCountry] = useState(countries[19]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("Mr");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { referral } = useSelector((state) => state.referral);
  const navigate = useNavigate();
  const [activeRestaurants, setActiveRestaurants] = useState([]);

  useEffect(() => {
    myGa();
    setConnected(hasAuthentication());
    getActiveRestaurants()
      .then((res) => {
        setActiveRestaurants(res.data);
      })
      .catch((err) => {
        setActiveRestaurants([]);
      });
  }, []);

  useEffect(() => {
    setHasEmptyField(emptyField(name, email, message));
  }, [name, email, message]);

  const emptyField = (name, email, message) => {
    return (
      name === "" || email === "" || message === "" || !goodFormatEmail(email)
    );
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    if (hasEmptyField) {
      setHasEmptyField(true);
      return;
    }

    setLoading(true);

    sendMail({
      to: EMAIL_COMPANY,
      templateId: "d-69e625f2e87f433c953da291c8ca5f12",
      data: {
        name: name,
        email: email,
        message: message,
        country: country.label,
        civility: value,
      },
    })
      .then((res) => {
        setLoading(false);
        handleReset();
        setSuccess(true);

        sendMail({
          to: email,
          templateId: DEFAULT_TEMPLATE,
          data: {
            subject: "Email confirmation",
            preheader:
              "We are successfully received your message. We will answer you as soon as possible",
            message:
              "Thank you for your message. We will answer you as soon as possible",
          },
        });
      })
      .catch((e) => {
        consoleLog("Error sending email", e);
        setLoading(false);
        setError(true);
      });
  };

  const handleReset = () => {
    setHasEmptyField(false);
    setMessage("");
    setEmail("");
    setName("");
  };

  return (
    <FadeComponent>
      <div>
        <Box className="menuPage">
          <section className="header">
            <div className="title2">{t("menupage_head_title")}</div>
            <div className="subTitle">
              {t("menupage_head_subTitle")}

              {!connected && (
                <FluidButton
                  text={t("menupage_register_btn")}
                  action={() => navigate(`signIn/${referral ? referral : ""}`)}
                />
              )}
            </div>
            {/* test */}
            {/* <button
              type="button"
              onClick={() => {
                throw new Error("Sentry Test Error");
              }}
            >
              Break the world
            </button>
            ; */}
            <div className="blocInscription">
              <div className="image">
                <img src={`${imagePath}/pub_oresto.jpg`} alt="pub oresto" />
              </div>
              <div className="formular">
                <div
                  style={{ fontSize: "xx-large", marginTop: 0 }}
                  className="title2"
                >
                  {t("menupage_contact_form_title")}
                </div>
                <FormControl sx={{ width: "100%", textAlign: "left" }}>
                  <FormLabel
                    color="warning"
                    id="demo-row-radio-buttons-group-label"
                  >
                    {t("civility")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Mme"
                      control={<Radio color="warning" />}
                      label="Mme"
                    />
                    <FormControlLabel
                      value="Mr"
                      control={<Radio color="warning" />}
                      label="Mr"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="warning" />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                <div className="personnal">
                  <div>
                    <TextField
                      color="warning"
                      label={`${t("lastname_label")} & ${t(
                        "firstname_label"
                      )}*`}
                      value={name}
                      variant="standard"
                      onChange={(e) => {
                        e.preventDefault();
                        setName(e.target.value);
                      }}
                      sx={{
                        marginTop: "1rem",
                        paddingTop: "1rem",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "1rem",
                      paddingTop: "1rem",
                    }}
                  >
                    <CountryInfo
                      setInfoCountry={setCountry}
                      selectCountryName={true}
                      variant="standard"
                    />
                  </div>
                </div>
                <div className="private">
                  <div>
                    <TextField
                      color="warning"
                      type="email"
                      label={`${t("email_address_label")}*`}
                      variant="standard"
                      onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                      }}
                      value={email}
                      sx={{
                        marginTop: "1rem",
                        paddingTop: "1rem",
                        width: "100%",
                      }}
                    />
                    <FormHelperText
                      className="errorText"
                      id="component-error-text"
                    >
                      <Collapse in={email && !goodFormatEmail(email)}>
                        {t("bad_email_format")}
                      </Collapse>
                    </FormHelperText>
                  </div>
                  <div>
                    <TextField
                      color="warning"
                      multiline
                      value={message}
                      label={`Message`}
                      placeholder="Write your message here..."
                      variant="standard"
                      onChange={(e) => {
                        e.preventDefault();
                        setMessage(e.target.value);
                      }}
                      sx={{
                        marginTop: "1rem",
                        paddingTop: "1rem",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
                {!loading && (
                  <div
                    style={{
                      backgroundColor: hasEmptyField ? "black" : greenOlive,
                    }}
                    className="button"
                    onClick={handleSubmit}
                  >
                    {t("menupage_contact_form_btn")}
                  </div>
                )}
                {loading && (
                  <div
                    className="button"
                    style={{
                      backgroundColor: "lightgray",
                    }}
                  >
                    <LoaderThreeDot />
                  </div>
                )}

                <TemporaryAlert
                  open={success}
                  setOpen={setSuccess}
                  text={t("success_email_send")}
                />
                <TemporaryAlert
                  open={error}
                  setOpen={setError}
                  text={t("error_email_send")}
                  severity="error"
                />
              </div>
            </div>
          </section>

          <section className="avantages">
            <div className="title">{t("menupage_avantage_title")}</div>
            <Row className="items">
              {avantages.map((a, key) => {
                return (
                  <Col key={key} sm={5} md={4}>
                    <Avantage t={t} avantage={a} />
                  </Col>
                );
              })}
            </Row>
          </section>

          <section className="final">
            <div className="title2">{t("menupage_final_title")}</div>
            <div className="bloc">
              <div className="subTitle">
                <div>{t("menupage_final_subTitle")}</div>
                <NavLink
                  style={{ textDecoration: "none", color: "white" }}
                  to={
                    connected
                      ? "/manage/menu"
                      : `signIn/${referral ? referral : ""}`
                  }
                >
                  <div className="btn">
                    {!connected ? (
                      <span>{t("menupage_final_commencer")}</span>
                    ) : (
                      <span>
                        Mon espace personnel{" "}
                        <svg
                          style={{ width: "5%", marginLeft: "1rem" }}
                          strokeWidth="4"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                      </span>
                    )}
                  </div>
                </NavLink>
              </div>

              <div>
                <img src={`${imagePath}/pubMenu.png`} alt="phone with menu" />
              </div>
            </div>

            <div className="btn2">
              <NavLink
                style={{ textDecoration: "none", color: "white" }}
                to={"/menu/6497fd81e3cfe9daa193d0d4"}
              >
                <Typography>{t("menupage_final_demo")}</Typography>
              </NavLink>
            </div>
          </section>
        </Box>

        {/* <div className="restaurants">
          <div className="title">Ils nous font confiance !</div>
          <div className="subTitle">
            Découvrez les restaurants qui ont choisi notre plateforme pour leur
            offrir un service de qualité. Vous pourrez vous inspirer de leurs
            menus et vous faire gagner du temps.
          </div>
          <div className="items">
            {activeRestaurants.map((r, key) => {
              return (
                <Partenaire
                  key={key}
                  restaurant={r}
                  t={t}
                  setSuccess={setSuccess}
                  setError={setError}
                />
              );
            })}
          </div>
        </div> */}
        <div className="footer">
          <Footer />
        </div>
      </div>
    </FadeComponent>
  );
};

const Partenaire = ({ restaurant }) => {
  return (
    <FadeComponent>
      <div className="partenaire">
        <div className="logo">
          <Avatar
            sx={{ width: "20%", height: "20%", border: "1px solid orange" }}
            src={restaurant.logo}
          />
        </div>
        <Typography variant="h6" className="name">
          {restaurant.name}
        </Typography>
        <Chip
          label={"Voir le menu"}
          variant="outlined"
          size="small"
          sx={{
            borderColor: "orange",
            textTransform: "uppercase",
            color: "orange",
            cursor: "pointer",
          }}
        />
      </div>
    </FadeComponent>
  );
};

export default EMenuPage;
